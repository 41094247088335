import * as React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"

const Products = () => {
  return (

    <Layout>
    
   
    <div className="featuted-product-wrap section-space--pt_120">
            <div className="container">
                <div className="row align-items-center featuted-product-one">
                    <div className="col-lg-6 col-md-6 order-lg-2 order-1">
                        <div className="product-thumbnail">
                            <a href="#"><img src="assets/images/featured-product/pr1.png" className="img-fluid" alt="Featured Image"/></a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 order-lg-1 order-2">
                        <div className="featured-product-contect">
                            <h3 className="section-title"><a href="shop-left-sidebar.html">Vibra One</a></h3>
                            <h6 className="featured-pice">
                                Just from <span> $35.00</span>
                            </h6>

                            <div className="button-box section-space--mt_60">
                                <a href="shop-left-sidebar.html" className="btn--text-icon">Discover Now <i className="arrow_carrot-2right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="featuted-product-wrap section-space--pt_120">
            <div className="container">
                <div className="row align-items-center featuted-product-one">
                    <div className="col-lg-8 col-md-6 order-lg-1 order-1">
                        <div className="product-thumbnail">
                            <a href="#"><img src="assets/images/featured-product/pr2-600x479.png" className="img-fluid" alt="Featured Image"/></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 order-lg-2 order-2">
                        <div className="featured-product-contect text-right">
                            <h3 className="section-title"><a href="shop-left-sidebar.html">Vibra Four</a></h3>
                            <h6 className="featured-pice">
                                Just from <span> $35.00</span>
                            </h6>

                            <div className="button-box section-space--mt_60">
                                <a href="shop-left-sidebar.html" className="btn--text-icon">Discover Now <i className="arrow_carrot-2right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
    </Layout>

  )
}

export default Products
